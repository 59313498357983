<template>
    <div id="app">
        <HelloWorld />
        <vue-danmaku ref="danmaku" v-model="danmus" fontSize="24" style="height:300px; width:100%; position: absolute;top: 0;">
        </vue-danmaku>
        <input type="text" v-model="inputSend">
        <button @click="send">发送</button>
    </div>
</template>
  
<script>
import vueDanmaku from 'vue-danmaku'
import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'index',
    components: {
        HelloWorld,
        vueDanmaku
    },
    data() {
        return {
            inputSend: '',
            danmus: ['danmu1', 'danmu2', 'danmu3', '...'],
        }
    },
    methods: {
        send() {
            this.$refs['danmaku'].add(this.inputSend)
        }
    }
}
</script>
  
<style>

</style>
  